import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { FaTimes } from 'react-icons/fa'
import { BsDownload } from 'react-icons/bs'
import styled from '@emotion/styled'
import moment from 'moment'

import { PageIntro, NewsletterForm, FormSection } from '#sections'
import { Layout, SectionTitle, LotBlock, ArrowButton } from '#components'
import { useDebounce, useThemeOptions } from '#hooks'
import maxPrices from './maxPrices'

const StyledSelectWrap = styled.div`
  position: relative;

  span {
    position: absolute;
    top: 50%;
    right: 17px;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 9px 6px 0 6px;
    border-color: #000 transparent transparent transparent;
    pointer-events: none;
    transition: all 0.3s;
  }

  select:active {
    ~ span {
      transform: translateY(-50%) rotate(180deg);
    }
  }
`

const StyledDropdown = styled.button`
  position: relative;

  span:nth-of-type(2) {
    position: absolute;
    top: 50%;
    right: 17px;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 9px 6px 0 6px;
    border-color: #c8102e transparent transparent transparent;
    pointer-events: none;
    transition: all 0.3s;
  }

  &.is-active {
    /* border-bottom: 2px solid transparent !important; */

    span:nth-of-type(2) {
      transform: translateY(-50%) rotate(180deg);
      border-color: #2c2c2c transparent transparent transparent;
    }
  }

  &:hover {
    span:nth-of-type(2) {
      border-color: #2c2c2c transparent transparent transparent;
    }
  }
`

export default function Auction({
  pageContext: { auction, featuredImage, future },
}) {
  const [perPage, setPerPage] = useState(400)
  const [query, setQuery] = useState(``)
  const [maxPrice, setMaxPrice] = useState(`999999999999999999`)
  const [propertyType, setPropertyType] = useState(`All`)
  const [filteredLots, setFilteredLots] = useState(auction.Lots)
  const [currLots, setCurrLots] = useState(filteredLots.slice(0, perPage))
  const debouncedQuery = useDebounce(query, 500)
  const { miniCatalogues, currentCatalogue, showCatalogues } = useThemeOptions()
  const [showMini, setShowMini] = useState(false)

  useEffect(() => {
    setCurrLots(filteredLots.slice(0, perPage))
  }, [perPage, filteredLots])

  useEffect(() => {
    const lots = auction.Lots.filter((lot) => {
      const toCheck = `lot ${lot.LotNumber} ${lot.FullAddress}`.toLowerCase()

      return (
        toCheck.includes(debouncedQuery.toLowerCase()) &&
        ((maxPrice === `999999999999999999` &&
          (lot.GuidePrice === `SOLD PRIOR` ||
            lot.GuidePrice === `Sold Prior ` ||
            lot.GuidePrice === `Sold Prior` ||
            lot.GuidePrice === `Sold prior` ||
            lot.GuidePrice === 'No Reserve')) ||
          !lot.GuidePrice ||
          parseInt(lot.GuidePrice.replace(/[^0-9]/g, ``)) <= maxPrice) &&
        (lot.PropertyType === propertyType || propertyType === `All`)
      )
    })

    setFilteredLots(lots)
  }, [debouncedQuery, auction.Lots, maxPrice, propertyType])

  // const formattedDate = new Intl.DateTimeFormat(`en-UK`, {
  //   dateStyle: `long`,
  // }).format(new Date(auction.AuctionDate))

  const formattedDate = moment(auction.AuctionDate).format(`MMMM Do, YYYY`)
    const auctionDate = new Date(auction.AuctionDate)
    const formattedAuctionTime = `${auctionDate.getHours()}:${auctionDate.getMinutes()}`

  const aucTitle = future
    ? `Listings for our Upcoming Auction`
    : `Previous Lots`
  const aucDesc = future
    ? `<strong class="font-bold">${formattedAuctionTime}AM on ${formattedDate}</strong><br>THIS AUCTION WILL BE REMOTE BIDDING ONLY`
    : `Results from the auction on<br><strong class="font-bold">${formattedDate}</strong>`

  return (
    <Layout title={`All Lots for ${formattedDate}`}>
      <PageIntro
        title={aucTitle}
        description={aucDesc}
        preview={false}
        backgroundImage={featuredImage}
      />
      <div className="bg-white relative pb-55 md:pb-65">
        <div className="container pt-45 md:pt-55">
          <div className="flex flex-wrap -mx-8">
            <div className="w-full lg:w-2/3 px-8">
              <div className="flex items-center flex-wrap -mx-8">
                <div className="w-full px-8 pb-20">
                  <SectionTitle
                    title="All Lots"
                    subtitle={`For ${formattedDate}`}
                  />
                </div>
                <div className="w-full px-8 md:w-auto md:min-w-215 xl:min-w-330 flex-grow xl:flex-grow-0 mb-10 md:mb-0">
                  <div className="relative">
                    <button
                      type="button"
                      className={`flex justify-between items-center absolute top-100p inset-x-0 bg-gray-500 text-white font-semibold text-13 py-5 text-left px-16 border-t-0 w-full transition-all duration-300 border-b-2 border-l-2 border-r-2 border-gray-200 outline-none hover:bg-gray-700 transform ${
                        query === ``
                          ? `opacity-0 invisible -translate-y-full`
                          : `opacity-100 visible translate-y-0`
                      }`}
                      onClick={() => setQuery(``)}
                    >
                      Reset Search Query
                      <FaTimes className="mb-1" />
                    </button>
                    <input
                      className={`relative block w-full border-2 bg-white text-17 md:text-19 placeholder-gray-500 text-black pt-7 pb-5 px-16 leading-normal outline-none transition-all duration-300 hover:shadow-md hover:border-gray-400 focus:border-gray-500 ${
                        query === ``
                          ? `border-gray-200`
                          : `border-gray-500 shadow-md`
                      }`}
                      type="text"
                      autoComplete="off"
                      name="search"
                      id="search"
                      placeholder="Lot No, Postcode, Address"
                      value={query}
                      onChange={(e) => setQuery(e.target.value)}
                    />
                  </div>
                </div>
                <div className="w-1/2 px-8 md:w-190 xl:w-210">
                  <StyledSelectWrap>
                    <button
                      type="button"
                      className={`flex justify-between items-center absolute top-100p inset-x-0 bg-gray-500 text-white font-semibold text-13 py-5 text-left px-16 border-t-0 w-full transition-all duration-300 border-b-2 border-l-2 border-r-2 border-gray-200 outline-none hover:bg-gray-700 transform ${
                        maxPrice === `999999999999999999`
                          ? `opacity-0 invisible -translate-y-full`
                          : `opacity-100 visible translate-y-0`
                      }`}
                      onClick={() => setMaxPrice(`999999999999999999`)}
                    >
                      Reset Max Price
                      <FaTimes className="mb-1" />
                    </button>
                    <select
                      name="max-price"
                      id="max-price"
                      className={`relative outline-none block w-full bg-gray-200 text-17 md:text-19 px-16 leading-normal pt-7 pb-5 appearance-none transition-all duration-300 hover:shadow-md hover:border-gray-400 focus:border-gray-500 border-2 cursor-pointer ${
                        maxPrice === `999999999999999999`
                          ? `border-gray-200`
                          : `border-gray-500 shadow-md`
                      }`}
                      value={maxPrice}
                      onChange={(e) => setMaxPrice(e.target.value)}
                    >
                      <option value="999999999999999999" disabled>
                        Max Price
                      </option>
                      {maxPrices.map(({ value, label }) => (
                        <option key={value} value={value}>
                          {label}
                        </option>
                      ))}
                    </select>
                    <span />
                  </StyledSelectWrap>
                </div>
                <div className="w-1/2 px-8 md:w-auto md:flex-grow md:max-w-190 xl:max-w-full">
                  <StyledSelectWrap>
                    <button
                      type="button"
                      className={`flex justify-between items-center absolute top-100p inset-x-0 bg-gray-500 text-white font-semibold text-13 py-5 text-left px-16 border-t-0 w-full transition-all duration-300 border-b-2 border-l-2 border-r-2 border-gray-200 outline-none hover:bg-gray-700 transform ${
                        propertyType === `All`
                          ? `opacity-0 invisible -translate-y-full`
                          : `opacity-100 visible translate-y-0`
                      }`}
                      onClick={() => setPropertyType(`All`)}
                    >
                      Reset Property Type
                      <FaTimes className="mb-1" />
                    </button>
                    <select
                      name="property-type"
                      id="property-type"
                      className={`relative outline-none block w-full bg-gray-200 text-17 md:text-19 px-16 leading-normal pt-7 pb-5 appearance-none transition-all duration-300 hover:shadow-md hover:border-gray-400 focus:border-gray-500 border-2 cursor-pointer ${
                        propertyType === `All`
                          ? `border-gray-200`
                          : `border-gray-500 shadow-md`
                      }`}
                      value={propertyType}
                      onChange={(e) => setPropertyType(e.target.value)}
                    >
                      <option value="All" disabled>
                        Property Type
                      </option>
                      {auction.LotsPropertyTypes.map((type) => (
                        <option key={type} value={type}>
                          {type.substring(0, type.indexOf(`-`))}
                        </option>
                      ))}
                    </select>
                    <span />
                  </StyledSelectWrap>
                </div>
              </div>
            </div>
            <div className="w-full lg:w-1/3 px-8 pt-5 lg:pt-0">
              {!!showCatalogues && false && (
                <div className="pt-6">
                  <a
                    className="flex items-center bg-red text-white px-22 justify-between text-19 pt-9 pb-7 transition-all duration-300 hover:bg-gray-700"
                    href={currentCatalogue.url}
                    target={currentCatalogue.target}
                  >
                    <span>{currentCatalogue.title}</span>
                    <span>
                      <BsDownload />
                    </span>
                  </a>

                  <div className="relative mt-10 z-40">
                    <div
                      className={`overflow-hidden absolute top-0 inset-x-0 border-l-2 border-r-2 border-b-2 border-gray-700 bg-white pt-45 shadow-post transition-all duration-300 ${
                        showMini ? `visible opacity-100` : `opacity-0 invisible`
                      }`}
                    >
                      {miniCatalogues &&
                        miniCatalogues.map(({ catalogue }) => (
                          <a
                            className="flex items-center bg-white text-gray-700 px-22 justify-between text-19 pt-9 pb-7 transition-all duration-300 hover:bg-gray-200"
                            href={catalogue.url}
                            target={catalogue.target}
                            key={catalogue.title}
                          >
                            <span
                              dangerouslySetInnerHTML={{
                                __html: catalogue.title,
                              }}
                            />
                            <span>
                              <BsDownload />
                            </span>
                          </a>
                        ))}
                    </div>
                    <StyledDropdown
                      type="button"
                      className={`relative flex w-full items-center justify-between text-19 px-22 bg-white border-2 pt-7 pb-5 transition-all duration-300 hover:border-gray-700 hover:text-gray-700 ${
                        showMini
                          ? `is-active border-gray-700 text-gray-700`
                          : `border-red text-red`
                      }`}
                      onClick={() => setShowMini(!showMini)}
                    >
                      <span>View Mini Catalogues</span>
                      <span />
                    </StyledDropdown>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="pt-45 lg:pt-65 pb-25 lg:pb-55">
            <div className="flex flex-wrap -mx-8">
              {currLots.map((lot) => (
                <div
                  className="w-full md:w-1/2 lg:w-1/3 px-8 mb-20 md:mb-40"
                  key={lot.ID}
                >
                  <LotBlock noviewing lot={lot} />
                </div>
              ))}
              {!currLots.length && (
                <div className="text-center py-65 text-27 font-semibold w-full">
                  Sorry, no results found for your search query.
                </div>
              )}
            </div>
            {perPage < filteredLots.length && (
              <div className="text-center pt-40">
                <ArrowButton
                  variant="accent"
                  type="button"
                  onClick={() => setPerPage(perPage + 6)}
                >
                  Load More
                </ArrowButton>
              </div>
            )}
          </div>
        </div>
      </div>
      <NewsletterForm />
      <FormSection />
    </Layout>
  )
}

Auction.propTypes = {
  pageContext: PropTypes.object.isRequired,
}
